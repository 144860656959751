import React from 'react'
import { Button } from '@nextui-org/react'
import Image from 'next/image'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    try {
      this.postError(error, errorInfo)
    } catch (err) {
      console.error('Cannot post error msg to server')
    }
  }

  async postError(error, errorInfo) {
    const userAgent = navigator?.userAgent;
    
    let user
    if (this.props.user) {
      user = {
        id: this.props.user.id,
        fullname: this.props.user.fullname,
        imperial: this.props.user.imperial,
        deviceModel: this.props.user.deviceModel,
        deviceType: this.props.user.deviceType,
        deviceOsVersion: this.props.user.deviceOsVersion,
        cookies: this.props.user.cookies,
        locale: this.props.user.locale,
        appVersion: this.props.user.appVersion
      }
    }
    
    const err = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))) // convert err to plain json
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ chError: true, userAgent, error: err, errorInfo, user })
    }
    const res = await fetch(`${process.env.API}/client-errors`, config)
    if (!res.ok) {
      console.error('Error logging error', res)
    } else {
      console.log('Client error successfully logged')
    }
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='mt-12 mb-12'>
          <div className='text-xl text-center'>
            Mischievous Hound! 
            <div className='flex justify-center mb-4'><Image src='/hound.png' width={50} height={50} alt='Mischievous Hound'/></div>
            <div className='max-w-[400px] mx-auto'>
              {this.props.errorMsg || 'Ouch, there was an error!'}
            </div>
          </div>
          <div className='flex justify-center mt-4'>
            <Button
              size='sm'
              color='primary'
              onPress={() => {
                if (this.props.redirect) {
                  window.location.href = this.props.redirect
                } else {
                  this.setState({ hasError: false })
                }
              }}
            >
              {this.props.tryAgain || 'Try again?'}
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary